<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
body {
  overflow-x: hidden;
  font-family: Arial, Helvetica, sans-serif, "microsoft yahei", "pingfang sc";
  background: white;
}
.header {
  width: 100%;
  height: 400px;
  background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/pingtai/header.png") no-repeat;
  background-size: 100% 100%;
  div {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    h3 {
      position: relative;
      font-size: 50px;
      color: #fff;
      padding-top: 20%;
    }
    h3::after {
      position: absolute;
      top: 110%;
      left: 0%;
      content: "";
      width: 50px;
      height: 2px;
      background-color: #fff;
    }
  }
}
.content {
  width: 100%;
  .wrap {
    width: 100vw;
    background-color: rgba(204, 204, 204, 0.2);
    .content_nav {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      text-align: center;
      padding: 5vh 0;
      a {
        position: relative;
        color: #333;
        flex: 1;
        font-size: 22px;
        opacity: 0.7;
        -webkit-tap-highlight-color: transparent;
        margin: 0 10px;
        text-decoration: none;
      }
      a.active {
        color: #c00000;
        font-weight: bold;
      }
      a.active::after {
        content: "";
        position: absolute;
        left: 50%;
        width: 50%;
        transform: translateX(-50%);
        height: 2px;
        opacity: 0.6;
        background-color: #c00000;
        bottom: -10px;
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .header {
    height: 150px;
    div {
      h3 {
        font-size: 24px;
        margin-left: 10%;
        font-weight: normal;
        padding-top: 20%;
      }
      h3::after {
        width: 30px;
      }
    }
  }

  .content {
    .wrap {
      .content_nav {
        display: flex;
        flex-direction: column;
        padding: 2vh 0;
        a {
          width: 90%;
          font-size: 16px;
          text-align: left;
          margin: 2vh auto;
        }
        a.active::after {
          left: 0;
          transform: translateX(0);
        }
      }
    }
  }
}
</style>
